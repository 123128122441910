import React, { useRef, useState } from 'react'
import { styled } from "twin.macro"
import SampleEntry from './SampleEntry'
import Button from "react-bootstrap/Button"
import TypeOfAnalysis from './TypeOfAnalysis'
import LeftChevIcon from '../../icons/leftChevIcon'
import SampleReception from './SampleReception'
import BxsChevronRightCircleIcon from '../../icons/rightChevIcon'
import SaveIconE from '../../icons/saveIcon'
import { setEchantillons } from '../../../firebase/insertData'

const Footer = styled.div`
	display: flex;
	align-items: center;
    flex-direction: column;
	align-self: flex-end;
	width: 100%;
	margin-top: auto;
`

const FooterLine = styled.span`
	width: 100%;
	display: block;
	border-bottom: 0.3px solid #d1d1d173;
	margin-bottom: 15px;
`
export const Savebtn = styled(Button)`
	background: #0d8a72;
	width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
	align-self: flex-end;
	display: flex;
    align-items: center;
    justify-content: center;
	visibility: ${({first}) =>
		first ? "hidden" : "visible"};
	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		border: none;
		cursor: default;
		background: #E5E5E5;
		color : #A5ADB2;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`
const LeftChevron = styled(LeftChevIcon)`
	margin-right: 5px;
`

const RightChev = styled(BxsChevronRightCircleIcon)`
	margin-left: 5px;
`

const SaveIcon = styled(SaveIconE)`
	margin-left: 5px;
`

const ContainerFragment = styled.div`
	display: flex;
	min-height: inherit;
	flex-direction: column;
`

const BtnsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`

export default function AddNewSample() {
	const [step, setStep] = useState(0)

	// First step :: Choose Type Of Analysis

	const [typeOfAnalysis, setTypeOfAnalysis] = useState("")

	function handleChange({target}) {
		setTypeOfAnalysis(target.id)
	}

	// Second Step :: Sample Entry

	const [data, setData] = useState([])
	const [modalShow, setModalShow] = useState(false);
	const [params, setParams] = useState({})

	Object.filter = (obj, predicate) => Object.keys(obj).filter( key => predicate(obj[key]) ).reduce( (res, key) => (res[key] = obj[key], res), {} )

	function handleParams(e) {
		setParams(oldData => {
			const filtred = Object.filter(oldData, elm => (elm))

			return {
				...filtred,
				[e.target.name]: e.target.checked
			}
		})
	}

	/**
	 * Parametres
	 */

	const parametres = [
		{
			title : "Plante",
			params : [
				"Date de réception/Date de prélèvement (inférieur à 24h)",
				"Etat de la plante",
				"Température (+4 °C à +20 °C)",
				"Humidité (Inférieur à 50%)",
				"Emballage (Sac à usage unique)",
				"Informations relatives à l'échantillon",
			]
		},
		{
			title : "Eau",
			params : [
				"Date de réception/Date de prélèvement (inférieur à 24h)",
				"Emaballage (Flacon en plastique ou en verre stérile)",
				"Quantité (1L)",
				"Température (+4 °C à +20 °C)",
				"Informations relatives à l'échantillon",
			]
		},
		{
			title : "Sol",
			params : [
				"Date de réception/Date de prélèvement (inférieur à 24h)",
				"Emaballage (Sac en plastique jetable)",
				"Quantité (1Kg)",
				"Température (+4 °C à +20 °C)",
				"Humidité (Inférieur à 50%)",
				"Informations relatives à l'échantillon",
			]
		},
		{
			title : "Semences",
			params : [
				"Date de réception/Date de prélèvement (inférieur à 24h)",
				"Emaballage",
				"Quantité (En fonction du type d'analyse)",
				"Traitement",
				"Informations relatives à l'échantillon",
			]
		}
	]

	/** Modal Refs */

	const sampleName = useRef()
	const sampleRef = useRef()
	const sampleQuantity = useRef()
	const sampleValidity = useRef()

	const [identity, setIdentity] = useState({
		sampleType: "Plante",
		sapmleDepotDate: new Date().toDateInputValue(),
		analysisType: "Bactériologique",
		aimedBy: ""
	})

	function handleChangeId({ target }) {
		const { name, value } = target

		/**
		 * Reset params while changing sample type
		 */
		if (name === "sampleType")
			setParams({})
		setIdentity(oldIdentity => ({
			...oldIdentity,
			[name]: value
		}))
	}

	function handleSampleAdd() {

		const sampleVal = sampleName.current.value
		const samplRefeVal = sampleRef.current.value
		const sampleQuantityVal = sampleQuantity.current.value
		const sampleValidityVal = sampleValidity.current.value

		if (sampleVal && samplRefeVal && sampleValidityVal && sampleQuantityVal) {
			setData(oldData => ([...oldData, {
				sample: sampleVal,
				ref: samplRefeVal,
				quantity: sampleQuantityVal,
				validity: sampleValidityVal
			}]))
		}
		setModalShow(false)
	}

	function handleDelItem(item) {
		const filterd = data.filter(elm => elm.ref !== item)
		setData(filterd)
	}

	function handleSave() {
		const filterdParams = Object.filter(params, elm => (elm))
		alert(JSON.stringify([data, identity, filterdParams]))
		const echantillonNumber = data.length
		const echantillons = {
			echantillons : data
		}
		const PushData = {...echantillons, ...identity, typeOfAnalysis, ...applicant, ...sampleDetails, echantillonNumber}
		setEchantillons(PushData)
	}

	//  third step :: 
	/** Applicant Infos */
	/** Previous button will not work till we imlement a custum constext for those two pages */
	/** the same for previous page's data */
	
	const [applicant, setApplicant] = useState({
		clientName: "",
		clientAddress: "",
		clientPhone: "",
		clientFax: "",
		clientEmail: ""
	})

	const [sampleDetails, setSampleDetails] = useState({
		sampler: "",
		location: "",
		date: "",
		time: "",
		purchaseOrder: "",
		from: ""
	})

	function handleAppChange({target}) {
		const {name, value} = target
		setApplicant(olaData => ({
			...olaData,
			[name]: value
		}))
	}

	function handleSamChange({target}) {
		const {name, value} = target
		setSampleDetails(olaData => ({
			...olaData,
			[name]: value
		}))
	}

	function handleSubmit(e) {
		e.preventDefault()
		console.log("Submitted")
		console.log(applicant, sampleDetails)
	}

	function renderButton() {
		if (step > 2)
			return null;
		else if ( step === 2) {
			return (
				<Savebtn onClick={() => handleSave()}>
					Enregistrer<SaveIcon />
				</Savebtn>
			)
		} else {
			return (
				<Savebtn onClick={() => setStep(oldVal => oldVal + 1)}>Suivant<RightChev /></Savebtn>
			)
		}
	}

	const steps = [
		{
			label: 0,
			component(step) {
				return <TypeOfAnalysis isActive={this.label === step} state={this.state} />
			},
			state: {
				typeOfAnalysis,
				handleChange
			}
		},
		{
			label: 1,
			component(step) {
				return <SampleEntry isActive={this.label === step} state={this.state} />
			},
			state: {
				data,
				setData,
				modalShow,
				setModalShow,
				params,
				setParams,
				handleParams,
				parametres,
				sampleName,
				sampleRef,
				sampleQuantity,
				sampleValidity,
				identity,
				setIdentity,
				handleChangeId,
				handleSampleAdd,
				handleDelItem,
				handleSave
			}
		},
		{
			label: 2,
			component(step) {
				return <SampleReception isActive={this.label === step} state={this.state} />
			},
			state: {
				applicant,
				setApplicant,
				sampleDetails,
				setSampleDetails,
				handleAppChange,
				handleSamChange,
				handleSubmit
			}
		},
	]

	return (
		<ContainerFragment>
			{steps.map( el => {
				return (
				<React.Fragment key={el.label}>
					{el.component(step)}
				</React.Fragment>
			)})}

			<Footer>
				<FooterLine/>
				<BtnsWrapper>
					<Savebtn variant="secondary" onClick={() => setStep(oldVal => oldVal - 1)} disabled={step === 0}><LeftChevron/>Précédent</Savebtn>
					{/* <Savebtn onClick={() => setStep(oldVal => oldVal + 1)}>Suivant</Savebtn> */}
					{renderButton()}
				</BtnsWrapper>
			</Footer>
		</ContainerFragment>
	)
}
