import * as React from "react";

function BxsVialIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M7 22a4.965 4.965 0 003.535-1.465l9.193-9.193.707.708 1.414-1.414-8.485-8.486-1.414 1.414.708.707-9.193 9.193C2.521 14.408 2 15.664 2 17s.521 2.592 1.465 3.535A4.965 4.965 0 007 22zM18.314 9.928L15.242 13H6.758l7.314-7.314 4.242 4.242z" />
    </svg>
  );
}

export default BxsVialIcon;
