import React , { useState } from 'react'
import Container from "react-bootstrap/Container"
import Radio from 'react-bootstrap/FormCheck'
import ProgressBar from './ProgressBar'
import FormR from 'react-bootstrap/FormGroup'
import {FooterLine,Footer, Savebtn, BtnsWrapper} from './ConformSamples'
import { styled } from "twin.macro"


const Form = styled.div`
    display: flex;
    flex-wrap: wrap;
    width : 100%;
    justify-content: space-between;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    margin : 20px 0px 50px 0px;
`
const Input = styled.input`
    box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
    border-radius: 4px;
    padding: 8px;
    background: #FFFFFF;
    border: 1px solid #A3ACB9;
    outline : none;
    margin : 2px 0px;
    box-sizing: border-box;
    width : 100%;
    &:hover {
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        border-color: #86b7fe;
	}
	&:focus {
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        border-color: #86b7fe;
	}
    
`
const Label = styled.label`
    display : flex;
    flex-direction : column;
    width : ${props => props.size ? props.size + '%' : '45%'};
`
const Title = styled.h4`
    color : #0D8A72;
`
const CheckBox = styled.div`
    margin : 40px 0px;
    display : flex;
    flex-direction : ${props => props.column ? 'column' : 'row'};
    justify-content : space-between;
    .form-check {
        margin : ${props => props.column ? '8px 0px 8px 20px' : ''}
    }
`
export default function Recu() {
    const [steps, setSteps] = useState(0)
    return (
        <Container>
            <h1>Réception des Echantillons pour Analyses Microbiologiques</h1>
            <ProgressBar length={5} active={steps}/>
            {
                steps === 0 ?
            <>
            <Title>Demandeur</Title>
            <Form>
                <Label>
                    Nom ou Raison Sociale
                    <Input ></Input>
                </Label>
                 <Label>
                    Adresse
                    <Input></Input>
                </Label>
                <Label size={30}>
                    Téléphone
                    <Input></Input>
                </Label>
                <Label size={30}>
                    Fax
                    <Input></Input>
                </Label>
                <Label size={30}>
                    E-mail
                    <Input></Input>
                </Label>
                <Label size={22}>
                    Préleveur
                    <Input></Input>
                </Label>
                <Label size={22}>
                    Lieu
                    <Input></Input>
                </Label>
                <Label size={22}>
                    Date
                    <Input></Input>
                </Label>
                <Label size={22}>
                    Heure
                    <Input></Input>
                </Label>
                <Label>
                    Bon de commande N° 
                    <Input ></Input>
                </Label>
                 <Label>
                    Du
                    <Input></Input>
                </Label>
            </Form>
            </> : ''
            }
            {
                steps === 1 ?
                <>
                    <Title>Échantillon</Title>
                    <CheckBox>
                        Type d'échantillon
                        <Radio type="checkbox" label="Eau" />
                        <Radio type="checkbox" label="Aliments" />
                        <Radio type="checkbox" label="Surface" />
                        <Radio type="checkbox" label="Autre" />
                    </CheckBox>
                    <Form>
                        <Label size={100}>
                            Nature d’échantillon
                            <Input></Input>
                        </Label>
                        <Label>
                            Nombre d’échantillons
                            <Input></Input>
                        </Label>
                        <Label>
                            Référence /Lot
                            <Input></Input>
                        </Label>
                        <Label>
                            T°C lors du prélèvement
                            <Input></Input>
                        </Label>
                        <Label>
                            T°C lors de la réception
                            <Input></Input>
                        </Label>
                        <Label>
                            Date de réception
                            <Input></Input>
                        </Label>
                        <Label>
                            Date de prélèvement
                            <Input></Input>
                        </Label>
                        <Label>
                            Date de prélèvement
                            <Input></Input>
                        </Label>
                        <Label>
                            Quantité
                            <Input></Input>
                        </Label>
                    </Form>
                </>
                : ''
            }
            {
                steps === 2 ?
                <>
                <Title>Analyse demandée</Title>
                <CheckBox column>
                    <Radio type="checkbox" label="Bactériologique" />
                    <Radio type="checkbox" label="Mycologique" />
                    <Radio type="checkbox" label="Virologique" />
                    <Radio type="checkbox" label="Nématologique" />
                    <Radio type="checkbox" label="Entomologique" />
					<FormR className="mb-3" controlId="formBasicCheckbox">
						<Radio type="checkbox" label="Check me out" />
					</FormR>
                </CheckBox>
                </>
                : ''
            }
            {
                steps === 3 ?
                <>
                    <Title>Prix et règlement</Title>
                    <Form>
                        <Label size={22}>
                            Prix unitaire
                            <Input></Input>
                        </Label>
                        <Label size={22}>
                            Nombre d’échantillons
                            <Input></Input>
                        </Label>
                        <Label size={22}>
                            Total (TTC)
                            <Input></Input>
                        </Label>
                        <Label size={22}>
                            Avence
                            <Input></Input>
                        </Label>
                    </Form>
                    <Title>Réception</Title>
                </>
                : ''
            }
            {
                steps === 4 ?
                <>
                    
                </>
                : ''
            }
            <Footer>
                <FooterLine />
                <BtnsWrapper>
                    <Savebtn disabled={steps === 0} onClick={() => setSteps(steps - 1)}>Précédent</Savebtn>
                    <Savebtn onClick={() => setSteps(steps + 1)} disabled={steps === 4} >Suivant</Savebtn>
                </BtnsWrapper>
            </Footer>
        </Container>
    )
}
