import React, { useState } from 'react';
import Select from 'react-select';
import { styled } from "twin.macro"


const Span = styled.span`
	font-size: 14px;
    font-weight: 600;
    color: #697386;
    margin-bottom: .5rem;
    display: block;
`


export default ({title, options, handleSelecetOnChange, value}) => {

	return (
	<React.Fragment>
		<Span>{title}</Span>
		<Select
			options={options}
			onChange={handleSelecetOnChange}
			value={value}
		/>
	</React.Fragment>
	)
}