// icon:share-circle | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function ShareCircleIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1.3em"
      width="1.3em"
      style={{cursor : 'pointer'}}
      {...props}
    >
      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2m2 14v-3c-3.61 0-6.19 1.43-8 4 .72-3.67 2.94-7.27 8-8V6l5 5-5 5z" />
    </svg>
  );
}

export default ShareCircleIcon;
