import React from 'react'
import { styled } from "twin.macro"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


const BForm = styled(Form)`
	width: 100%;
	font-family: "Montserrat";
`
const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`
const FormCheck = styled(Form.Check)`
	font-weight: 600;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`

const SmBreathingRoom = styled.div`
	height: 0.5rem;
    width: 100%;
`

const ContainerFragment = styled.div`
  display: ${( {activated} ) => activated ? "block" : "none"};
`

export default function PageTwo({isActive, state}) {
	const { analysis, otherAnalysis, handleAnalysisChange, handleOtherAnalysis } = state

	return (
		<ContainerFragment activated={isActive}>
			<h2>Analyse demandée</h2>
			<BForm>
				<Row>
					<Col>
						<Form.Group id="one" as={Row}>
						<FormLabel as="legend" column sm={2}>
						Type d'échantillon
						</FormLabel>
						<Col sm={10}>
							<FormCheck
								type="radio"
								className="my-1 mr-sm-4"
								id="Bacteriologique"
								label="Bactériologique"
								name="analysis"
								onChange={handleAnalysisChange}
								checked={analysis === "Bacteriologique"}
								custom
							/>
							<SmBreathingRoom />
							<FormCheck
								type="radio"
								className="my-1 mr-sm-4"
								id="Mycologique"
								label="Mycologique"
								name="analysis"
								onChange={handleAnalysisChange}
								checked={analysis === "Mycologique"}
								custom
							/>
							<SmBreathingRoom />
							<FormCheck
								type="radio"
								className="my-1 mr-sm-4"
								id="Virologique"
								label="Virologique"
								name="analysis"
								onChange={handleAnalysisChange}
								checked={analysis === "Virologique"}
								custom
							/>
							<SmBreathingRoom />
							<FormCheck
								type="radio"
								className="my-1 mr-sm-4"
								id="Nematologique"
								label="Nématologique"
								name="analysis"
								onChange={handleAnalysisChange}
								checked={analysis === "Nematologique"}
								custom
							/>
							<SmBreathingRoom />
							<FormCheck
								type="radio"
								className="my-1 mr-sm-4"
								id="Entomologique"
								label="Entomologique"
								name="analysis"
								onChange={handleAnalysisChange}
								checked={analysis === "Entomologique"}
								custom
							/>
							<SmBreathingRoom />
							<Row className="my-1 mr-sm-4">
							<Col xs="auto" className="my-1">
								<FormCheck
									type="radio"
									id="Autre"
									label="Autre"
									className="my-1 mr-sm-4"
									name="analysis"
									onChange={handleAnalysisChange}
									checked={analysis === "Autre"}
									custom
								/>
							</Col>
							<Col sm={6} className="my-1">
								<FormControl id="inlineFormInputName" placeholder="Autre type d'échantillon" disabled={analysis !== "Autre"} onChange={handleOtherAnalysis} value={analysis === "Autre" ? otherAnalysis : ""} />
							</Col>
							</Row>
						</Col>
						</Form.Group>
					</Col>
				</Row>

			</BForm>
		</ContainerFragment>
	)
}
