import React from "react";


function LogoutIcon(props) {
	return (
		<svg
		  viewBox="0 0 24 24"
		  fill="currentColor"
		  height="1em"
		  width="1em"
		  {...props}
		>
		  <path d="M18 2H6a1 1 0 00-1 1v9l5-4v3h6v2h-6v3l-5-4v9a1 1 0 001 1h12a1 1 0 001-1V3a1 1 0 00-1-1z" />
		</svg>
	  );
}

export default LogoutIcon;
