import React from 'react'
import { styled } from "twin.macro"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


const BForm = styled(Form)`
	width: 100%;
	font-family: "Montserrat";
`
const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`


const FormCheck = styled(Form.Check)`
	font-weight: 600;
`

const ContainerFragment = styled.div`
  display: ${( {activated} ) => activated ? "block" : "none"};
`

export default function PageOne({isActive, state}) {

	const { 
		sampleType, 
		otherSampleType, 
		cultureType, 
		otherCultureType, 
		distribution, 
		sampleDetails 
	} = state

	const { 
		culture, 
		area, 
		variety, 
		originOfPlants, 
		sampleNumberOne, 
		sampleRefOne 
	} = sampleDetails

	const { 
		handleSampleDetailsChange,
		handleSampleTypeChange, 
		handleOtherSampleTypeChange, 
		handleCultureTypeChange, 
		handleOtherCultureTypeChange, 
		handleDistributionChange 
	} = state

	return (
		<ContainerFragment activated={isActive}>
			<h2>Échantillon</h2>
			<BForm>
				<Row>
					<Col>
						<Form.Group id="one" as={Row}>
							<FormLabel as="legend" column sm={2}>
								Type d'échantillon
							</FormLabel>
							<Col>
								<FormCheck
									inline
									type="radio"
									className="my-1 mr-sm-4"
									id="Plante"
									label="Plante"
									name="sampleType"
									onChange={handleSampleTypeChange}
									checked={sampleType === "Plante"}
									custom
								/>
								<FormCheck
									inline
									type="radio"
									className="my-1 mr-sm-4"
									id="Sol"
									label="Sol"
									name="sampleType"
									onChange={handleSampleTypeChange}
									checked={sampleType === "Sol"}
									custom
								/>
								<FormCheck
									inline
									type="radio"
									className="my-1 mr-sm-4"
									id="Eau"
									label="Eau"
									name="sampleType"
									onChange={handleSampleTypeChange}
									checked={sampleType === "Eau"}
									custom
								/>
								<FormCheck
									inline
									type="radio"
									className="my-1 mr-sm-4"
									id="AutreEchan"
									label="Autre"
									name="sampleType"
									onChange={handleSampleTypeChange}
									checked={sampleType === "AutreEchan"}
									custom
								/>
								<Col as={Row} sm={6}>
									<FormControl placeholder="Autre type d'échantillon" disabled={sampleType !== "AutreEchan"} onChange={handleOtherSampleTypeChange} value={sampleType === "AutreEchan" ? otherSampleType : ""} />
								</Col>
							</Col>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col>
						<Form.Group id="two" as={Row}>
						<FormLabel as="legend" column sm={2}>
							Type de culture
						</FormLabel>
						{/* <Col xs="auto" className="my-1"> */}
						<Col>
							<FormCheck
								inline
								type="radio"
								className="my-1 mr-sm-4"
								id="pleinChamp"
								label="Plein champ"
								name="cultureType"
								onChange={handleCultureTypeChange}
								checked={cultureType === "pleinChamp"}
								custom
							/>
							<FormCheck
								inline
								type="radio"
								className="my-1 mr-sm-4"
								id="sousSerre"
								label="Sous Serre"
								name="cultureType"
								onChange={handleCultureTypeChange}
								checked={cultureType === "sousSerre"}
								custom
							/>
							<FormCheck
								inline
								type="radio"
								className="my-1 mr-sm-4"
								id="horsSol"
								label="Hors sol"
								name="cultureType"
								onChange={handleCultureTypeChange}
								checked={cultureType === "horsSol"}
								custom
							/>
							<FormCheck
								inline
								type="radio"
								className="my-1 mr-sm-4"
								id="autreCulture"
								label="Autre"
								name="cultureType"
								onChange={handleCultureTypeChange}
								checked={cultureType === "autreCulture"}
								custom
							/>
							<Col as={Row} sm={6}>
								<FormControl id="inlineFormInputName" placeholder="Autre type de culture" disabled={cultureType !== "autreCulture"} onChange={handleOtherCultureTypeChange} value={cultureType === "autreCulture" ? otherCultureType : ""} />
							</Col>
						</Col>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col>
				<Form.Group id="Culture">
					<FormLabel>Culture</FormLabel>
					<FormControl type="text" name="culture" onChange={handleSampleDetailsChange} value={culture} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="location">
					<FormLabel>Superficie</FormLabel>
					<FormControl type="text" name="area" onChange={handleSampleDetailsChange} value={area} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="anOtherOne">
					<FormLabel>Variété/PG</FormLabel>
					<FormControl type="text" name="variety" onChange={handleSampleDetailsChange} value={variety} />
				</Form.Group>
				</Col>
				</Row>


				<Row>
					<Col>
						<Form.Group id="purchase-order">
							<FormLabel>Origine des plantes</FormLabel>
							<FormControl type="text" name="originOfPlants" onChange={handleSampleDetailsChange} value={originOfPlants} />
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col>
						<Form.Group id="two" as={Row}>
						<FormLabel as="legend" column sm={2}>
							Distribution
						</FormLabel>
						<Col sm={10}>
							<FormCheck
								inline
								type="radio"
								className="my-1 mr-sm-4"
								id="Localisée"
								label="Localisée"
								name="distribution"
								onChange={handleDistributionChange}
								checked={distribution === "Localisée"}
								custom
							/>
							<FormCheck
								inline
								type="radio"
								className="my-1 mr-sm-4"
								id="Généralisé"
								label="Généralisé"
								name="distribution"
								onChange={handleDistributionChange}
								checked={distribution === "Généralisé"}
								custom
							/>
						</Col>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col>
						<Form.Group id="SampleCount">
							<FormLabel>Nombre d’échantillons</FormLabel>
							<FormControl type="text" name="sampleNumberOne" onChange={handleSampleDetailsChange} value={sampleNumberOne} />
						</Form.Group>
					</Col>
					<Col>
						<Form.Group id="sampleRefOne">
							<FormLabel>Référence</FormLabel>
							<FormControl type="text" name="sampleRefOne" onChange={handleSampleDetailsChange} value={sampleRefOne} />
						</Form.Group>
					</Col>
				</Row>

			</BForm>
		</ContainerFragment>
	)
}
