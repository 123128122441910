import React from 'react'
import { navigate } from "@reach/router"
import { useAuth } from "../../context/auth"

/** this <PrivateRoute/> is accessible olny for logged users */

export default function PrivateRoute({ component: Component, ...rest}) {
	const auth = useAuth()
	console.log(auth)
	if (auth.currentUser)
		return <Component {...rest} />
	navigate("/", { replace: true })
	return null
}
