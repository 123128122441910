import firebase from "gatsby-plugin-firebase"
import 'firebase/firestore';

 async function setEchantillons(data) {
   const db  = firebase.firestore()
   let res = await db.collection("echantillons").doc()
   const collectionId = {
        clientCode  : res.id
   }
   res.set({...data, ...collectionId})
}
export {setEchantillons}