import React, { useState } from 'react'
import { styled } from "twin.macro"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Table from "react-bootstrap/Table"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


import LeftChevIcon from "../icons/leftChevIcon"




const Footer = styled.div`
	display: flex;
	align-items: center;
    flex-direction: column;
`

const Elem = styled.div`
    padding: 5px 10px;
`

const BreathingRoom = styled.div`
    height: 1.5rem;
    width: 100%;
`

const SmBreathingRoom = styled.div`
    height: 1rem;
    width: 100%;
`
const FooterLine = styled.span`
	width: 100%;
	display: block;
	border-bottom: 0.3px solid #d1d1d173;
	margin-bottom: 15px;
`
const Savebtn = styled(Button)`
	background: #0d8a72;
	width: auto;
	min-width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
	align-self: flex-end;
	display: flex;
    align-items: center;
    justify-content: center;

	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		background: #0b6d5a;
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`

const LeftChevron = styled(LeftChevIcon)`
	margin-right: 5px;
`

const BtnsWrapper = styled.div`
	width: 50%;
	display: flex;
	justify-content: space-around;
`

const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`


export default function FinalReportPage() {
	const [state, setState] = useState({
		name: null,
		date: null
	})
	function handleChange({name, value}) {
		setState({
			[name]: value
		})
	}

	return (
		// Needs only dynamic data
		<React.Fragment>
			<h1>Validation de rapport des analyses Phytopathologiques</h1>
			<SmBreathingRoom />
			<Row>
				<Col>
					<Elem>Code échantillon  : APXXXXXXXXX</Elem>
				</Col>
			</Row>
			<SmBreathingRoom />
			<h2>Identification</h2>
			<Row>
				<Col>
					<Elem>Demandeur : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Prélèvement effectué par : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Bon de commande : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Echantillon déposé par : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Type d’échantillon : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Date de dépôt des échantillons : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Référence demandeur : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Types d’analyse : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Référence demandeur : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Pathogène recherché : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Lieu et date de prélèvement : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Technique : Undefined</Elem>
				</Col>
			</Row>
			<BreathingRoom />

			<Row>
				<Col>
					<h2>Résultats</h2>
						<Elem>Résultats ....</Elem>
				</Col>
				<Col>
					<h2>Remise des résultats</h2>
						<Form.Group id="name">
							<FormLabel>Analyse prise en charge par</FormLabel>
							<FormControl type="text" name="name" onChange={handleChange} value={state.name}/>
						</Form.Group>
						<Form.Group id="date">
							<FormLabel>Date de remise des résultats</FormLabel>
							<FormControl type="date" name="date" onChange={handleChange} value={state.date}/>
						</Form.Group>
				</Col>
			</Row>
			<BreathingRoom />
			<h2>Recommandations</h2>
			<Row>
				<Col>
					<Elem>Recommandations ....</Elem>
				</Col>
			</Row>
			<BreathingRoom />
			<Footer>
				<FooterLine/>
				<BtnsWrapper>
					<Savebtn onClick={() => console.log("testing phase")}>Envoyer par email</Savebtn>
					<Savebtn onClick={() => console.log("testing phase")}>Imprimer</Savebtn>
				</BtnsWrapper>
			</Footer>
		</React.Fragment>
	)
}


