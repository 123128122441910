import React from 'react'
import { Link, navigate } from "gatsby"
import { styled } from "twin.macro"
import { useAuth } from "../context/auth"

import logo from "../images/logo.png"
import DashboardIcon from "../components/icons/dashboardIcon"
import AnalyseIcon from "../components/icons/analyseIcon"
import ChevronIcon from "../components/icons/chevronIcon"
import VialIcon from "../components/icons/vialIcon"
import ChartIcon from "../components/icons/chartIcon"
import CogIcon from "../components/icons/cogIcon"
import LogoutIcon from "../components/icons/logoutIcon"

const Nav = styled.div`
	height: 100vh;
	flex-basis: 280px;
	padding: 2rem 1rem 2rem;
	background-color: rgb(255, 255, 255);
	z-index: 100;
	display: flex;
    flex-direction: column;
`
const NavLogo = styled.div`
	background: url(${logo});
	background-repeat: no-repeat;
	background-position: top;
	width: 100%;
	height: 110px;
`

const NavName = styled.div``
const NavList = styled.div`
	display: grid;
	row-gap: 0.25rem;
`
const NavItem = styled(Link)`
	display: flex;
	align-items: center;
	color: #697386;
	font-size: .85rem;
	font-weight: 600;
	padding: 10px 8px;
	border-radius: 7px;
	outline: none;
	cursor: pointer;

	&:hover {
		background: #ecf6f4;
		color: #0d8a72;
		text-decoration: none;
	}
`

const NavIcon = styled.i`
	font-size: 1.25rem;
	margin-right: 0.65rem;
`
const NavLine = styled.span`
	display: block;
	border-bottom: 0.3px solid #d1d1d173;
`
const NavDropdown = styled.div`
	overflow: hidden;
	max-height: 40px;
	transition: 0.4s ease-in-out;
	&:hover {
		max-height: 100rem;
	}
`

/**implement is open for chevron as well for rotation */

const NavChevron = styled.i`
	font-size: 1.1rem;
	margin-left: auto;
	transition: 0.4s;
`
const NavDropdownCollapse = styled.div`
	display: grid;
	row-gap: 0.3rem;
	margin-top: 0.2rem;
`
const NavDropdownItem = styled(Link)`
	font-size: .85rem;
	font-weight: 600;
	color: #697386;
	display: flex;
	align-items: center;
	height: 40px;
	padding: 12px 0 10px 40px;
	border-radius: 7px;
	&:hover {
		background: #ecf6f4;
		color: #0d8a72;
		text-decoration: none;
	}
`
const Logout = styled.div`
	display: grid;
    row-gap: 0.25rem;
	margin-top: auto;
`
const FuncItem = styled.div`
	display: flex;
	align-items: center;
	color: #697386;
	font-size: .85rem;
	font-weight: 600;
	padding: 10px 8px;
	border-radius: 7px;
	outline: none;
	cursor: pointer;

	&:hover {
		background: #ecf6f4;
		color: #0d8a72;
	}
`


export default () => {

	const auth = useAuth()

	return (
		<Nav>
		<NavLogo />
		<NavList>
			<NavItem to="/dashboard">
				<NavIcon><DashboardIcon /></NavIcon>
				<NavName>Dashboard</NavName>
			</NavItem>
			<NavLine />
			<NavDropdown>
				<FuncItem>
					<NavIcon><VialIcon /></NavIcon>
					<NavName>Échantillons</NavName>
					<NavChevron><ChevronIcon /></NavChevron>
				</FuncItem>
				<NavDropdownCollapse>
					<NavDropdownItem to="/dashboard/add-new-sample">Nouveau échantillon</NavDropdownItem>
					<NavDropdownItem to="/dashboard/sample-list">Liste des échantillons</NavDropdownItem>
				</NavDropdownCollapse>
			</NavDropdown>
			<NavLine />
			{/* <NavItem to="/dashboard/sample-list">
				<NavIcon><VialIcon/></NavIcon>
				<NavName>Échantillons</NavName>
			</NavItem>
			<NavLine /> */}
			<NavItem to="/dashboard/elisa">
				<NavIcon><ChartIcon /></NavIcon>
				<NavName>Elisa</NavName>
			</NavItem>
			<NavLine />

			<NavItem to="/dashboard/pcrconv">
				<NavIcon><ChartIcon /></NavIcon>
				<NavName>PCR Conv</NavName>
			</NavItem>
			<NavLine />

			<NavItem to="/dashboard/pcrreal">
				<NavIcon><ChartIcon /></NavIcon>
				<NavName>PCR Real</NavName>
			</NavItem>
			<NavLine />

			<NavItem to="/dashboard/sample-reception">
				<NavIcon><CogIcon /></NavIcon>
				<NavName>Paramètres</NavName>
			</NavItem>
			<NavLine />
		</NavList>
		<Logout>
			<NavLine />
		{/** needs to be modified as div then we cal apply the singout */}
			<FuncItem onClick={() => {
				auth.logout()
				navigate("/", {replace: true})
			}} >
				<NavIcon><LogoutIcon /></NavIcon>
				<NavName>Se déconnecter</NavName>
			</FuncItem>

		</Logout>
	</Nav>
	)
}
