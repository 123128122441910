import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { styled } from "twin.macro"
import { useAuth } from "../context/auth"
import { Router } from "@reach/router"
import PrivateRoute from "../components/routing/PrivateRoute"
import Navbar from "../components/navbar"

import BarsIcon from "../components/icons/barsIcon"
import Spinner from "react-bootstrap/Spinner"


import Seo from "../components/seo"

import SampleEntry from "../components/dashboard/addNewSample/SampleEntry"
import SampleReception from "../components/dashboard/addNewSample/SampleReception"
import SampleList from "../components/dashboard/SampleList"
import GlobalStyling from "../components/utils/GlobalStyling"
import ConformSamples from "../components/dashboard/ConformSamples"
import PaillassePage from "../components/dashboard/Paillasse"
import MicrobiologicalAnalyzesPage from "../components/dashboard/PaillasseMicrobiologicalAnalyzes"
import ElisaPage from "../components/dashboard/Elisa"
import MicroWaterPage from "../components/dashboard/MicroWater"
import PCRConvPage from "../components/dashboard/PCRConvPage"
import FinalReportPage from "../components/dashboard/FinalReport"
import ReportValidationPage from "../components/dashboard/ReportValidation"
import CulturingBacteriologique from "../components/dashboard/CulturingBacteriologique"
import CulturingMycologiquePage from "../components/dashboard/CulturingMycologique"
import PCRRealTPage from "../components/dashboard/PCRRealTPage"
import MicroFoodPage from "../components/dashboard/MicroFood"
import MicroAirPage from "../components/dashboard/MicroAir"
import MicroSurfacePage from "../components/dashboard/MicroSurface"
import Recu from '../components/dashboard/recu'
import AddNewSample from "../components/dashboard/addNewSample/AddNewSample"
import ConformSamplesPhytopa from "../components/dashboard/ConformSamplesPhytopa"
import ConformSamplesMicro from "../components/dashboard/ConformSamplesMicro"


const Dashboard = styled.div`
	width: 100%;
	height: inherit;
	display: flex;
`


/**Main section */
const MainWrapper = styled.main`
	flex: 1;
	height: 100%;
`
const Header = styled.header`
	flex: 1;
	background: #0d8a72;
	padding: 0 1rem;
	z-index: 100;
`
const HeaderContainner = styled.div`
	height: 3rem;
	display: flex;
	align-items: center;
`
const HeaderIcon = styled.i`
	margin-right: auto;
	font-size: 2rem;
	color: #fff;
	cursor: pointer;
`
const ImgProfile = styled.div`
	margin-left: auto;
	background-color: #fff;
	height: 35px;
	width: 35px;
	border-radius: 50%;
`

const ContentWrapper = styled.div`
	/* height: calc(100vh - 48px); */
	min-height: calc(100vh - 48px);
    padding: 1.25rem;
`
const Content = styled.div`
	background: white;
	padding: 0.9rem;
	border-radius: 10px;
	/* min-height: 100%; */
	/* min-height: fit-content; */
	min-height: 85vh;
	/* display: flex; */

`

const AgriSpinner = styled(Spinner)`
	background: #0d8a72;
`
const PageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: inherit;
	width: inherit;
`


const DashboardPage = ({ location }) => {
	const [checking, setChecking] = useState(true)
	const auth = useAuth()

	/**
	 * prev path
	 */
	const prevPath = location.state?.prevPath;
	
	useEffect(() => {
		console.log("this is the prev path :: ", prevPath)
		if (!auth.currentUser){
			navigate("/", { replace: true })
		}
		if (prevPath !== "/") {
			console.log("i came from nowhere")
			setInterval(() => {
				setChecking(false)
			}, 1337)
		} else {
			console.log("i came from the /")
			setChecking(false)
		}
	}, [])

	if (checking){
		return(
			<>
				<GlobalStyling/>
				<PageWrapper><AgriSpinner animation="grow" /></PageWrapper>
			</>
		)
	}

	return (
	<React.Fragment>
	<Seo title="Dashboard" />
		<GlobalStyling />
		<Dashboard>
			<Navbar />
			{/** main section */}
			<MainWrapper>
				<Header>
					<HeaderContainner>
						<HeaderIcon><BarsIcon /></HeaderIcon>
						<ImgProfile />
					</HeaderContainner>
				</Header>
				<ContentWrapper>
					<Content>
						{/** dashboard routing */}
					<Router style={{minHeight: "81vh"}}>
						{/* <PrivateRoute path="/dashboard/sample-entry" component={SampleEntry} /> */}
						{/* <PrivateRoute path="/dashboard/sample-reception" component={SampleReception} /> */}
						{/* <PrivateRoute path="/dashboard/sample-list" component={SampleList} /> */}
						{/* <PrivateRoute path="/dashboard/" exact component={CulturingPage} /> */}
						<PrivateRoute path="/dashboard/add-new-sample" component={AddNewSample} />
						<PrivateRoute path="/dashboard/sample-entry" component={SampleEntry} />
						<PrivateRoute path="/dashboard/sample-reception" component={SampleReception} />
						<PrivateRoute path="/dashboard/sample-list" component={SampleList} />
						{/* <PrivateRoute path="/dashboard/" exact component={ReportValidationPage} /> */}
						{/* <PrivateRoute path="/dashboard/" exact component={FinalReportPage} /> */}
						{/* <PrivateRoute path="/dashboard/" exact component={CulturingMycologiquePage} /> */}
						{/* <PrivateRoute path="/dashboard/" exact component={CulturingBacteriologique} /> */}
						<PrivateRoute path="/dashboard/pcrconv" exact component={PCRConvPage} />
						<PrivateRoute path="/dashboard/pcrreal" exact component={PCRRealTPage} />
						{/* <PrivateRoute path="/dashboard/" exact component={PaillassePage} /> */}
						<PrivateRoute path="/dashboard/elisa" exact component={ElisaPage} />
						<PrivateRoute path="/dashboard/phytopa/:id" exact component={ConformSamplesPhytopa} />
						<PrivateRoute path="/dashboard/micro/:id" exact component={ConformSamplesMicro} />
						{/* <PrivateRoute path="/dashboard/" exact component={SampleReception} /> */}
						{/**Pages for second Part */}
						{/* <PrivateRoute path="/dashboard/" exact component={MicrobiologicalAnalyzesPage} /> */}
						{/* <PrivateRoute path="/dashboard/" exact component={MicroWaterPage} /> */}
						{/* <PrivateRoute path="/dashboard/" exact component={MicroFoodPage} /> */}
						{/* <PrivateRoute path="/dashboard/" exact component={MicroAirPage} /> */}
						{/* <PrivateRoute path="/dashboard/" exact component={Recu} /> */}
					</Router>
					</Content>
				</ContentWrapper>
			</MainWrapper> 
		</Dashboard>
	</React.Fragment>
	)
}

export default DashboardPage