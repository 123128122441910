import React, { useState, useRef } from 'react'
import { styled } from "twin.macro"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


import DeleteIcon from "../../icons/deleteIcon"
import LeftChevIcon from "../../icons/leftChevIcon"

/**boostrap Modified component */


const BForm = styled(Form)`
	width: 100%;
	font-family: "Montserrat";
`
const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`

const BButton = styled(Button)`
	background: #0d8a72;
	width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		background: #0b6d5a;
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`

const DelIcon = styled.i`
	font-size: 1.25rem;
	cursor: pointer;
	&:hover {
		color: #eb2f06;
	}
`

const Rightbtn = styled(Button)`
	background: #0d8a72;
	width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
	align-self: flex-end;
	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		background: #0b6d5a;
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`

const Leftbtn = styled(Button)`
	/* background: #0d8a72; */
	width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
    display: flex;
    align-items: center;
    justify-content: center;
	&:hover {
		/* background: #0b6d5a; */
	}
	&:focus {
		/* background: #0b6d5a; */
		border: none;
	}
	&:disabled {
		/* background: #0b6d5a; */
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		/* background: #0b6d5a; */
		border: none;
	}
`


const BtnsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

const FooterLine = styled.span`
	width: 100%;
	display: block;
	border-bottom: 0.3px solid #d1d1d173;
	margin-bottom: 15px;
`

const Footer = styled.div`
	display: flex;
    flex-direction: column;
`

const LeftChevron = styled(LeftChevIcon)`
	margin-right: 5px;
`

const ContainerFragment = styled.div`
	display: ${(props) =>
		props.activated ? "block" : "none"};
`

export default function SampleReception({isActive, state}) {
	const {
		applicant,
		setApplicant,
		sampleDetails,
		setSampleDetails,
		handleAppChange,
		handleSamChange,
		handleSubmit
	} = state

	// /** Applicant Infos */
	// /** Previous button will not work till we imlement a custum constext for those two pages */
	// /** the same for previous page's data */
	
	// const [applicant, setApplicant] = useState({
	// 	clientCode: "",
	// 	clientName: "",
	// 	clientAddress: "",
	// 	clientPhone: "",
	// 	clientFax: "",
	// 	clientEmail: ""
	// })

	const {clientName, clientAddress, clientPhone, clientFax, clientEmail} = applicant

	// const [sampleDetails, setSampleDetails] = useState({
	// 	sampler: "",
	// 	location: "",
	// 	date: "",
	// 	time: "",
	// 	purchaseOrder: "",
	// 	from: ""
	// })

	const { sampler, location, date, time, purchaseOrder, from } = sampleDetails


	// function handleAppChange({target}) {
	// 	const {name, value} = target
	// 	setApplicant(olaData => ({
	// 		...olaData,
	// 		[name]: value
	// 	}))
	// }

	// function handleSamChange({target}) {
	// 	const {name, value} = target
	// 	setSampleDetails(olaData => ({
	// 		...olaData,
	// 		[name]: value
	// 	}))
	// }

	// function handleSubmit(e) {
	// 	e.preventDefault()
	// 	console.log("Submitted")
	// 	console.log(applicant, sampleDetails)
	// }


	return (
		<ContainerFragment activated={isActive}>
			<h1>Réception des Echantillons pour	Analyses Phytopathologiques</h1>

			<h2>Demandeur</h2>
			<BForm>
				<Row>
					{/* <Col>
				<Form.Group id="client-code">
					<FormLabel>Code client</FormLabel>
					<FormControl type="text"  placeholder="code" name="clientCode" onChange={handleAppChange} value={clientCode}/>
				</Form.Group>
				</Col> */}
				<Col>
				<Form.Group id="identity">
					<FormLabel>Nom ou Raison Sociale</FormLabel>
					<FormControl type="text" name="clientName" placeholder="client" onChange={handleAppChange} value={clientName} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="Address">
					<FormLabel>Adresse</FormLabel>
					<FormControl type="text" name="clientAddress" placeholder="adresse" onChange={handleAppChange} value={clientAddress} />
				</Form.Group>
				</Col>
				</Row>

				<Row>
					<Col>
				<Form.Group id="phone">
					<FormLabel>Téléphone</FormLabel>
					<FormControl type="text"  placeholder="Téléphone" name="clientPhone" onChange={handleAppChange} value={clientPhone} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="Fax">
					<FormLabel>Fax</FormLabel>
					<FormControl type="text" placeholder="Fax" name="clientFax" onChange={handleAppChange} value={clientFax} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="E-mail">
					<FormLabel>Email</FormLabel>
					<FormControl type="email" name="clientEmail" placeholder="e-mail@name.com" onChange={handleAppChange} value={clientEmail} />
				</Form.Group>
				</Col>
				</Row>

				<Row>
					<Col>
				<Form.Group id="sampler">
					<FormLabel>Préleveur</FormLabel>
					<FormControl type="text" placeholder="Préleveur" name="sampler" onChange={handleSamChange} value={sampler} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="location">
					<FormLabel>Lieu</FormLabel>
					<FormControl type="text" placeholder="Lieu" name="location" onChange={handleSamChange} value={location} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="date">
					<FormLabel>Date</FormLabel>
					<FormControl type="date" name="date" onChange={handleSamChange} value={date} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="time">
					<FormLabel>Heure</FormLabel>
					<FormControl type="time" name="time" onChange={handleSamChange} value={time} />
				</Form.Group>
				</Col>
				</Row>


				<Row>
					<Col>
						<Form.Group id="purchase-order">
							<FormLabel>Bon de commande N°</FormLabel>
							<FormControl type="text" name="purchaseOrder" onChange={handleSamChange} value={purchaseOrder} />
						</Form.Group>
					</Col>
					<Col>
						<Form.Group id="from">
							<FormLabel>Du</FormLabel>
							<FormControl type="text" placeholder="from" name="from" onChange={handleSamChange} value={from} />
						</Form.Group>
					</Col>
				</Row>
			</BForm>
			{/* <Footer>
				<FooterLine/>
				<BtnsWrapper>
					<Leftbtn variant="secondary" onClick={() => console.log("33-34")}><LeftChevron/>Précédent</Leftbtn>
					<Rightbtn onClick={handleSubmit}>Sauvegarder</Rightbtn>
				</BtnsWrapper>
			</Footer> */}
		</ContainerFragment>
	)
}
