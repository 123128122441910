import firebase from "gatsby-plugin-firebase"
import 'firebase/firestore';

async function getEchantillons(id)
{
    const db = firebase.firestore()
    let getData;
    if (id !== undefined)
        getData = await db.collection("echantillons").where("clientCode", "==", id).get()
    else
        getData = await db.collection("echantillons").get()
    return getData.docs.map(doc => doc.data());
}

export {getEchantillons}