import * as React from "react";

function BxsPrinterIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M19 7h-1V2H6v5H5a3 3 0 00-3 3v7a2 2 0 002 2h2v3h12v-3h2a2 2 0 002-2v-7a3 3 0 00-3-3zM8 4h8v3H8V4zm0 16v-4h8v4H8zm11-8h-4v-2h4v2z" />
    </svg>
  );
}

export default BxsPrinterIcon;