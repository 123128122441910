import React from 'react'
import { styled } from "twin.macro"

import MicroscopeIcon from "../../components/icons/microscope"
import CheckAllIcon from "../../components/icons/checkIcon"


const MicroIcon = styled(MicroscopeIcon)`
	width: 100%;
	height: 100%;

`

const RadioWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 67%;
	margin: 37px auto 0;
`

const Option = styled.label`
	position: relative;
	height: 175px;
	width: 175px;
`

const Radio = styled.input`
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
	opacity: 0;
`

const OptionInner = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
	text-align: center;
	border-radius: 12px;
	border: 1px solid;
	border-color: ${(props) =>
		props.ischecked ? "#0d8a72" : '#ddd'};
	cursor: pointer;
`

const Checkmark = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	color: #0d8a72;
	opacity: ${(props) =>
		props.ischecked ? "1" : "0"};
`

const Icon = styled.div`
	height: 50px;
	width: 50px;
`

const Name = styled.div`
	user-select: none;
`

const ContainerFragment = styled.div`
  display: ${(props) =>
		props.activated ? "block" : "none"};
`

export default function PageZero({isActive, state}) {

	const { typeOfAnalysis, handleChange } = state

	return (
		<ContainerFragment activated={isActive}>
			<h2>Choisissez le type d'analyse</h2>
			<RadioWrapper>
					<Option>
						<Radio type="radio" name="group1" id="Microbiologique" onChange={handleChange} checked={typeOfAnalysis === "Microbiologique"} />
						<OptionInner ischecked={typeOfAnalysis === "Microbiologique" ? 1 : 0}>
							<Checkmark ischecked={typeOfAnalysis === "Microbiologique" ? 1 : 0}>
								<CheckAllIcon height="30px" width="30px" />
							</Checkmark>
							<Icon><MicroIcon /></Icon>
							<Name>Analyse Microbiologique</Name>
						</OptionInner>
					</Option>
					<Option>
						<Radio type="radio" name="group1" id="Phytopathologique" onChange={handleChange} ckecked={typeOfAnalysis === "Phytopathologique"} />
						<OptionInner ischecked={typeOfAnalysis === "Phytopathologique" ? 1 : 0}>
							<Checkmark ischecked={typeOfAnalysis === "Phytopathologique" ? 1 : 0}>
								<CheckAllIcon height="30px" width="30px" />
							</Checkmark>
							<Icon><MicroIcon /></Icon>
							<Name>Analyse Phytopathologique</Name>
						</OptionInner>
					</Option>
			</RadioWrapper>
		</ContainerFragment>
	)
}
