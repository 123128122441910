import React, { useState } from 'react'
import { styled } from "twin.macro"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Table from "react-bootstrap/Table"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


import KebabIcon from "../icons/kebabIcon"
import LeftChevIcon from "../icons/leftChevIcon"
import PageOne from "./PageOne"

const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`

const Footer = styled.div`
	display: flex;
	align-items: center;
    flex-direction: column;
`

const DelIcon = styled.i`
	font-size: 1.25rem;
	cursor: pointer;
	&:hover {
		color: #eb2f06;
	}
`
const Elem = styled.div`
    padding: 5px 10px;
`

const BreathingRoom = styled.div`
    height: 1.5rem;
    width: 100%;
`

const SmBreathingRoom = styled.div`
    height: 1rem;
    width: 100%;
`
const FooterLine = styled.span`
	width: 100%;
	display: block;
	border-bottom: 0.3px solid #d1d1d173;
	margin-bottom: 15px;
`
const Savebtn = styled(Button)`
	background: #0d8a72;
	width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
	align-self: flex-end;
	display: flex;
    align-items: center;
    justify-content: center;

	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		background: #0b6d5a;
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`

const LeftChevron = styled(LeftChevIcon)`
	margin-right: 5px;
`

const BtnsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`

export default function MicrobiologicalAnalyzesPage() {
	const [formStep, setFormStep] = useState(0)
	const [show, setShow] = useState(false);
	const [data, setData] = useState([])



	const [elements, setElements] = useState({
		clientCode: "",
		clientName: "",
		depotDate: "",
		nomberOfSamples: ""
	})



	return (
		<React.Fragment>
			<h1>Paillasse des Analyses Microbiologiques</h1>
			<SmBreathingRoom />
			<Container>
				<Row>
					<Col>
						<Elem>Code échantillon : Undefined</Elem>
					</Col>
				</Row>
			</Container>
			<SmBreathingRoom />
			<Container>
			<h2>Identification</h2>
				<Row>
					<Col>
						<Elem>Type d’échantillon : Undefined</Elem>
					</Col>
				</Row>
				<Row>
					<Col>
						<Elem>Nombre d’échantillon : Undefined</Elem>
					</Col>
				</Row>
				<Row>
					<Col>
						<Elem>Date de dépôt de l’échantillon : Undefined</Elem>
					</Col>
				</Row>
				<Row>
					<Col><Elem>Types d’analyse : Undefined</Elem></Col>
				</Row>
				<Row>
					<Col><Elem>Date d’analyse : Undefined</Elem></Col>
				</Row>
			</Container>
			<SmBreathingRoom />

			<Container>
			<h2>Analyses demandées</h2>
				<Row>
					<Col sm={6}>
						<Form.Group>
								<FormLabel>analyses demandées</FormLabel>
								<FormControl as="select" custom name="analysisType">
									<option value="Bactériologique">ELISA</option>
									<option value="Mycologique">PCR conventionnelle</option>
									<option value="Virologique">PCR en temps réel</option>
									<option value="Nématologique">Mise en culture Bactériologique</option>
									<option value="Entomologique">Mise en culture Mycologique</option>
									<option value="Virologique">Mise en culture Virologique</option>
									<option value="Nématologique">Mise en culture Nématologique</option>
								</FormControl>
						</Form.Group>
					</Col>
				</Row>
			</Container>

			<BreathingRoom />
			<Footer>
				<FooterLine/>
				<BtnsWrapper>
					<Savebtn variant="secondary" onClick={() => console.log("33-34")}><LeftChevron/>Précédent</Savebtn>
					<Savebtn onClick={() => setShow(true)}>open modal</Savebtn>
				</BtnsWrapper>
			</Footer>
		</React.Fragment>
	)
}
