import * as React from "react";

function MicroscopeIcon(props) {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M11.441 13.604a1 1 0 01-.707-.293L7.198 9.772a1 1 0 010-1.414l6.363-6.363a.997.997 0 01.39-.242l2.121-.707a1 1 0 011.024.242l2.122 2.121a1 1 0 01.241 1.024l-.708 2.122a.991.991 0 01-.241.39l-6.362 6.366a1 1 0 01-.707.293zm6.362-7.366z" />
      <path d="M7.198 9.772l-1.416 1.415a1 1 0 000 1.415l2.122 2.12a1 1 0 001.414 0l1.414-1.413z" />
      <path d="M8 18.005H4a1 1 0 010-2h4a1 1 0 010 2z" />
      <path d="M20 23.005H4a1 1 0 010-2h16a1 1 0 010 2z" />
      <path d="M14.816 21.005a2.965 2.965 0 00.184-1 3 3 0 00-6 0 2.965 2.965 0 00.184 1z" />
      <path d="M17.873 7.583l-1.415 1.415A5.955 5.955 0 0118 13.005a6.048 6.048 0 01-3.455 5.431 2.971 2.971 0 01.455 1.57 2.645 2.645 0 01-.04.407A8.044 8.044 0 0020 13.005a7.945 7.945 0 00-2.127-5.422zM9.42 18.499a7.036 7.036 0 01-1.095-.56.983.983 0 01-.326.066H5.326a8.873 8.873 0 003.72 2.472A2.69 2.69 0 019 20.005a2.966 2.966 0 01.42-1.506z" />
    </svg>
  );
}

export default MicroscopeIcon;
