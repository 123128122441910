import React, { useState } from 'react'
import { styled } from "twin.macro"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
// import Modal from "react-bootstrap/Modal"
// import Table from "react-bootstrap/Table"
// import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
// import CustSelect from "../utils/Select"


// import KebabIcon from "../icons/kebabIcon"
// import LeftChevIcon from "../icons/leftChevIcon"
// import PageOne from "./PageOne"

const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`

const Footer = styled.div`
	display: flex;
	align-items: center;
    flex-direction: column;
`

const DelIcon = styled.i`
	font-size: 1.25rem;
	cursor: pointer;
	&:hover {
		color: #eb2f06;
	}
`
const Elem = styled.div`
    padding: 5px 10px;
`

const BreathingRoom = styled.div`
    height: 1.5rem;
    width: 100%;
`

const SmBreathingRoom = styled.div`
    height: 1rem;
    width: 100%;
`
const FooterLine = styled.span`
	width: 100%;
	display: block;
	border-bottom: 0.3px solid #d1d1d173;
	margin-bottom: 15px;
`
const Savebtn = styled(Button)`
	background: #0d8a72;
	width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
	align-self: flex-end;
	display: flex;
    align-items: center;
    justify-content: center;

	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		background: #0b6d5a;
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`

const BtnsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`

const FormCheck = styled(Form.Check)`
	font-weight: 600;
`


export default function ReportValidationPage() {
	const [formStep, setFormStep] = useState(0)
	const [show, setShow] = useState(false);

	return (
		<React.Fragment>
			<h1>Validation de rapport des analyses Phytopathologiques</h1>
			<SmBreathingRoom />
			{/* <h2>Échantillon </h2> */}
			<Row>
				<Col>
					<Elem>Code échantillon  : APXXXXXXXXX</Elem>
				</Col>
			</Row>
			<SmBreathingRoom />
			<h2>Identification</h2>
			<Row>
				<Col>
					<Elem>Demandeur : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Prélèvement effectué par : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Bon de commande : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Echantillon déposé par : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Type d’échantillon : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Date de dépôt des échantillons : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Référence demandeur : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Types d’analyse : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Référence demandeur : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Pathogène recherché : Undefined</Elem>
				</Col>
			</Row>
			<Row>
				<Col>
					<Elem>Lieu et date de prélèvement : Undefined</Elem>
				</Col>
				<Col>
					<Elem>Technique : Undefined</Elem>
				</Col>
			</Row>
			<BreathingRoom />
			<h2>Résultats</h2>
			<Row>
				<Col sm={4}>
					<Form.Group>
						{/* <FormLabel>Symptomes</FormLabel> */}
						{/* list of results */}
						<FormControl as="select" custom name="analysisType">
							<option value="Bactériologique">Bactériologique</option>
							<option value="Mycologique">Mycologique</option>
							<option value="Virologique">Virologique</option>
							<option value="Nématologique">Nématologique</option>
							<option value="Entomologique">Entomologique</option>
						</FormControl>
					</Form.Group>
				</Col>
			</Row>

			<BreathingRoom />
			<h2>Recommandations</h2>
			<Row>
				<Col>
					<Form.Group id="one" as={Row}>
						{/* <FormLabel as="legend" column sm={2}>
					Type d'échantillon
					</FormLabel> */}
						<Col sm={10}>
							<FormCheck
								type="checkbox"
								className="my-1 mr-sm-4"
								id="Bactériologique"
								label="Recommandation 1"
								custom
							/>
							<SmBreathingRoom />
							<FormCheck
								type="checkbox"
								className="my-1 mr-sm-4"
								id="Mycologique"
								label="Recommandation 2"
								custom
							/>
							<SmBreathingRoom />
							<FormCheck
								type="checkbox"
								className="my-1 mr-sm-4"
								id="Virologique"
								label="Recommandation 3"
								custom
							/>
							<SmBreathingRoom />
							<FormCheck
								type="checkbox"
								className="my-1 mr-sm-4"
								id="Nématologique"
								label="Recommandation 4"
								custom
							/>
							<SmBreathingRoom />
							<FormCheck
								type="checkbox"
								className="my-1 mr-sm-4"
								id="Entomologique"
								label="Recommandation 5"
								custom
							/>
							<SmBreathingRoom />
							<Row className="my-1 mr-sm-4">
								<Col xs="auto" className="my-1">
									<FormCheck type="checkbox" id="autoSizingCheck2" label="Autre" className="my-1 mr-sm-4" custom />
								</Col>
								<Col sm={6} className="my-1">
									<Form.Label htmlFor="inlineFormInputName" srOnly>
										Name
									</Form.Label>
									<Form.Control id="inlineFormInputName" placeholder="Jane Doe" />
								</Col>
							</Row>
						</Col>
					</Form.Group>
				</Col>
			</Row>

			<Footer>
				<FooterLine />
				<BtnsWrapper>
					<Savebtn onClick={() => console.log("testing phase")}>Valider</Savebtn>
				</BtnsWrapper>
			</Footer>
		</React.Fragment>
	)
}


