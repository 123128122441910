import React from 'react'
import { styled } from "twin.macro"
import "../utils/progressStyling.css"

function creatMyArr (length){
	const myArr = []
	for(let i = 0; i < length; i++){
		myArr.push(i)
	}
	return myArr;
}

const ProgressWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 37%;
	margin: 20px auto;
`
const Td = styled.td`
	background-color: ${(props) =>
		props.elType ? "rgb(0 0 0 / 8%)" : 'white'};
	cursor: ${(props) =>
		props.elType ? "not-allowed" : "pointer"};
`

const Balloon = styled.div`
	height: 55px;
	width: 55px;
	background-color: ${(props) =>
		props.active ? "#0d8a72" : 'white'};
	color: ${(props) =>
		props.active ? "white" : "#0d8a72"};
	border-radius: 50%;
	border: 2px solid #0d8a72;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	user-select: none;
`

const ProgressLine = styled.div`
	background-color: #0d8a72;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 34%;
	height: 2px;
	position: absolute;
	z-index: 0;
`

export default function ProgressBar({length, active}) {
	const list = creatMyArr(length)
	return (
		<>
			<ProgressWrap>
				{list.map((el) => (
					<React.Fragment key={el}>
						<Balloon active={el === active}>{el + 0x1}</Balloon>
					</React.Fragment>
				))}
				<ProgressLine></ProgressLine>
			</ProgressWrap>
		</>
	)
}
