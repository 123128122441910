import React, { useState } from 'react'
import { styled } from "twin.macro"

import Table from "react-bootstrap/Table"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


const Elem = styled.div`
    padding: 5px 10px;
`

const BreathingRoom = styled.div`
    height: 1.5rem;
    width: 100%;
`

const SmBreathingRoom = styled.div`
    height: 1rem;
    width: 100%;
`

const ContainerFragment = styled.div`
  display: ${(props) =>
		props.activated ? "block" : "none"};
`

export default function PageFour({isActive, state}) {

	const { applicantData, sampleData } = state

	const {
		clientCode,
		clientName,
		clientAddress,
		clientPhone,
		clientFax,
		clientEmail,
		sampler,
		location,
		date,
		time,
		purchaseOrder,
		from
	} = applicantData

	const {	
		// typeOfAnalysis,
		sampleType,
		otherSampleType,
		cultureType,
		otherCultureType,
		distribution,
		sampleDetails,
		analysis,
		otherAnalysis,
		price,
		reception
	} = sampleData

	const { 
		culture, 
		area, 
		variety, 
		originOfPlants, 
		sampleNumberOne, 
		sampleRefOne 
	} = sampleDetails

	const { unitPrice, sampleNumber, totalPrice, advanced } = price
	const { droppingBy, receivedBy, receptionTime } = reception

	const [data, setData] = useState([])
	const [elements, setElements] = useState({
		clientCode: "",
		clientName: "",
		depotDate: "",
		nomberOfSamples: ""
	})

	/**
	 * All data is Already stored in the each's object state
	 * so what you need is get data from each property's state
	 */

	return (
		<ContainerFragment activated={isActive}>
			<h1>Validation les informations</h1>
			<h2>Demandeur</h2>
			<Container>
				<Row>
					<Col sm={6}>
						<Elem>Nom ou Raison Sociale : {clientName} </Elem>
					</Col>
					<Col sm={6}>
						<Elem>Fax : {clientFax} </Elem>
					</Col>
				</Row>
				<Row>
					<Col sm>
						<Elem>Adresse : {clientAddress} </Elem>
					</Col>
					<Col sm><Elem>Email : {clientEmail} </Elem></Col>
				</Row>
				<Row>
					<Col sm><Elem>Tél : {clientPhone} </Elem></Col>
				</Row>
			</Container>
			<SmBreathingRoom />
			<Container>
				<Row>
					<Col>
						<Elem>Préleveur : {sampler} </Elem>
					</Col>
					<Col>
						<Elem>Lieu : {location} </Elem>
					</Col>
					<Col>
						<Elem>Date : {date} </Elem>
					</Col>
				</Row>
				<Row>
					<Col sm>
						<Elem>Bon de commande N° : {purchaseOrder} </Elem>
					</Col>
					<Col sm><Elem>Du : {from} </Elem></Col>
				</Row>
			</Container>
			<BreathingRoom />
			{/* same context data  */}
			<h2>Échantillon</h2>
			<Container>
				<Row>
					<Col sm={6}>
						<Elem>Type d'échantillon : {sampleType !== 'AutreEchan' ? sampleType : otherSampleType}</Elem>
					</Col>
					<Col sm={6}>
						<Elem>Origine des plantes : {originOfPlants} </Elem>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Elem>Type de culture : {cultureType !== 'AutreCulture' ? cultureType : otherCultureType}</Elem>
					</Col>
					<Col sm={6}>
						<Elem>Distribution : {distribution}</Elem>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Elem>Culture : {culture} </Elem>
					</Col>
					<Col sm={6}>
						<Elem>Nombre d’échantillons : {sampleNumberOne} </Elem>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Elem>Superficie : {area}</Elem>
					</Col>
					<Col sm={6}>
						<Elem>Référence : {sampleRefOne} </Elem>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Elem>Variété/PG : {variety} </Elem>
					</Col>
				</Row>
			</Container>
			<h2>Analyse demandée</h2>
			<Container>
				<Row>
					<Col>
						<Elem> {analysis !== "Autre" ? analysis : otherAnalysis} </Elem>
					</Col>
				</Row>
			</Container>
			<BreathingRoom />
			<h2>Prix et Règlement</h2>
			<Table striped hover responsive bordered>
				<thead>
					<tr>
					<th>Prix Unitaire</th>
					<th>Nombre d’échantillons</th>
					<th>Total (TTC)</th>
					<th>Avance</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{unitPrice}</td>
						<td>{sampleNumber}</td>
						<td>{totalPrice}</td>
						<td>{advanced}</td>
					</tr>
				</tbody>
			</Table>
			<BreathingRoom />
			<h2>Réception</h2>
			<Container>
				<Row>
					<Col sm={6}>
						<Elem>Déposé par : {droppingBy} </Elem>
					</Col>
					<Col sm={6}>
						<Elem>Heure de réception : {receivedBy} </Elem>
					</Col>
				</Row>
				<Row>
					<Col>
						<Elem>Réceptionné par : {receptionTime} </Elem>
					</Col>
				</Row>
			</Container>
		</ContainerFragment>
	)
}
