import React, { useState, useEffect} from 'react'
import { styled } from "twin.macro"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"

import LeftChevIcon from "../../components/icons/leftChevIcon"
import KebabIcon from "../../components/icons/kebabIcon"
import Pagination from 'react-bootstrap/Pagination'
import ShareCircleIcon from '../icons/shareIcon'
import { getEchantillons } from '../../firebase/getData'
import { navigate } from '@reach/router'
/**boostrap Modified component */


const EditIcon = styled(KebabIcon)`
	width: 16px;
    fill: slategray;
`

const BForm = styled(Form)`
	width: 100%;
	font-family: "Montserrat";
`
const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`

const BButton = styled(Button)`
	background: #0d8a72;
	width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		background: #0b6d5a;
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`

const DelIcon = styled.i`
	font-size: 1.25rem;
	cursor: pointer;
	&:hover {
		color: #eb2f06;
	}
`

const Rightbtn = styled(Button)`
	background: #0d8a72;
	width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
	align-self: flex-end;
	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		background: #0b6d5a;
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`

const Leftbtn = styled(Button)`
	/* background: #0d8a72; */
	width: 120px;
	height: 38px;
	font-size: 14px;
	border: none;
    display: flex;
    align-items: center;
    justify-content: center;
	&:hover {
		/* background: #0b6d5a; */
	}
	&:focus {
		/* background: #0b6d5a; */
		border: none;
	}
	&:disabled {
		/* background: #0b6d5a; */
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		/* background: #0b6d5a; */
		border: none;
	}
`


const BtnsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

const FooterLine = styled.span`
	width: 100%;
	display: block;
	border-bottom: 0.3px solid #d1d1d173;
	margin-bottom: 15px;
`

const Footer = styled.div`
	display: flex;
	align-items: center;
    flex-direction: column;
`

const LeftChevron = styled(LeftChevIcon)`
	margin-right: 5px;
`

const PaginationItem = styled(Pagination.Item)`
	.page-link {
		color: #0b6d5a;
	}
	&.active .page-link {
		z-index: 3;
		color: #fff;
		background-color: #0b6d5a;
		border-color: #0b6d5a;
	}
`

const PaginationPrev = styled(Pagination.Prev)`
	.page-link {
		color: #0b6d5a;
	}
	&.active .page-link {
		z-index: 3;
		color: #fff;
		background-color: #0b6d5a;
		border-color: #0b6d5a;
	}
`

const PaginationNext = styled(Pagination.Next)`
	.page-link {
		color: #0b6d5a;
	}
	&.active .page-link {
		z-index: 3;
		color: #fff;
		background-color: #0b6d5a;
		border-color: #0b6d5a;
	}
`



export default function SampleReception() {
	/** Applicant Infos */
	/** Previous button will not work till we imlement a custum constext for those two pages */
	/** the same for previous page's data */
	const [data, setData] = useState([])
	const [elements, setElements] = useState({
		clientCode: "",
		clientName: "",
		sapmleDepotDate: "",
		nomberOfSamples: ""
	})

	function getUrl(analysisType, clientCode){
		if (analysisType === 'Microbiologique')
			navigate(`/dashboard/micro/${clientCode}`)
		else if (analysisType === 'Phytopathologique')
			navigate(`/dashboard/phytopa/${clientCode}`)
	}
	useEffect(() => {
		getEchantillons().then(res => {
			setData(res)
		})
	}, [])
	return (
		<React.Fragment>
			<h1>Échantillons par client</h1>

			{/* <h2>Demandeur</h2> */}
			<Table striped hover responsive>
				<thead>
					<tr>
					<th>#</th>
					<th>Code client</th>
					<th>Demandeur</th>
					<th>Date de dépôt de l’échantillon</th>
					<th>Nombre d’échantillon</th>
					<th>{" "}</th>
					</tr>
				</thead>
				<tbody>
					{(() => {
						if (data.length === 0){
							return (
								<tr>
									<td colSpan="6">Aucune donnée à charger.</td>
								</tr>)
						}
						return (
							data.map((element, i) => (
								<tr key={i}>
									<td>{i + 1}</td>
									<td>{element.clientCode}</td>
									<td>{element.clientName}</td>
									<td>{element.sapmleDepotDate}</td>
									<td>{element.echantillonNumber}</td>
									<td><ShareCircleIcon onClick={() => getUrl(element.typeOfAnalysis, element.clientCode)}/></td>
									<td><DelIcon><EditIcon onClick={() => console.log(i)}/></DelIcon></td>
								</tr>
							))
						)})()
					}
				</tbody>
			</Table>
			<Footer>
				<Pagination>
					<PaginationPrev>Précédent</PaginationPrev>
					<PaginationItem active>{1}</PaginationItem>
					<PaginationItem>{2}</PaginationItem>
					<PaginationNext>Suivant</PaginationNext>
				</Pagination>
			</Footer>
		</React.Fragment>
	)
}
