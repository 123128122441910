import React, { useState } from 'react'
import { styled } from "twin.macro"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


/**boostrap Modified component */


const BForm = styled(Form)`
	width: 100%;
	font-family: "Montserrat";
`
const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`

const ContainerFragment = styled.div`
  display: ${(props) =>
		props.activated ? "block" : "none"};
`


export default function PageThree({isActive, state}) {

	const { price, handlePriceChange, reception, handleReceptionChange } = state
	const { unitPrice, sampleNumber, totalPrice, advanced } = price
	const { droppingBy, receivedBy, receptionTime } = reception


	return (
		<ContainerFragment activated={isActive}>
			<h2>Prix et Règlement</h2>
			<BForm>
				<Row>
					<Col>
						<Form.Group id="unitPrice">
							<FormLabel>Prix unitaire</FormLabel>
							<FormControl type="text" name="unitPrice" onChange={handlePriceChange} value={unitPrice}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group id="sampleNumber">
							<FormLabel>Nombre d’échantillons</FormLabel>
							<FormControl type="text" name="sampleNumber" onChange={handlePriceChange} value={sampleNumber} />
						</Form.Group>
					</Col>
				<Col>
				<Form.Group id="totalPrice">
					<FormLabel>Total (TTC)</FormLabel>
					<FormControl type="text" name="totalPrice" onChange={handlePriceChange} value={totalPrice} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="advanced">
					<FormLabel>Avence</FormLabel>
					<FormControl type="text" name="advanced" onChange={handlePriceChange} value={advanced} />
				</Form.Group>
				</Col>
				</Row>
				</BForm>

				<h2>Réception</h2>
				<BForm>
				<Row>
					<Col>
				<Form.Group id="droppingBy">
					<FormLabel>Déposé par</FormLabel>
					<FormControl type="text" name="droppingBy" onChange={handleReceptionChange} value={droppingBy} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="receivedBy">
					<FormLabel>Réceptionné par</FormLabel>
					<FormControl type="text" name="receivedBy" onChange={handleReceptionChange} value={receivedBy} />
				</Form.Group>
				</Col>
				<Col>
				<Form.Group id="receptionTime">
					<FormLabel>Heure de réception</FormLabel>
					<FormControl type="time" name="receptionTime" onChange={handleReceptionChange} value={receptionTime} />
				</Form.Group>
				</Col>
				</Row>
			</BForm>
		</ContainerFragment>
	)
}
